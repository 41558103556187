import {useEnvironment, useWixSdk, useExperiments} from '@wix/yoshi-flow-editor'
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import React, {useEffect, useState} from 'react'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import settingsParams, {HEADER_TEXT_ALIGNMENT} from '../../../settingsParams'
import {Button} from '../button'
import {useWidgetProps} from '../../hooks/widget-props'
import {SettingsTab, SettingsUpdatedEvent} from '../../../constants'
import s from './header.scss'
import {MobileHeader} from './mobile-header'
import {PersonalAgendaHeader} from './personal-agenda-header'

export const Header = () => {
  const {
    eventDetailsUrl,
    eventTitle,
    formattedEventDateAndLocation,
    navigate,
    installedApps,
    personalAgendaEnabled,
    forceShowPersonalAgendaUI,
  } = useWidgetProps()
  const {Wix} = useWixSdk()
  const settings = useSettings()
  const {isViewer, isMobile, isEditor} = useEnvironment()
  const showDateAndLocation = settings.get(settingsParams.showDateAndLocation)
  const headerTextAlignment = settings.get(settingsParams.headerTextAlignment)
  const alignmentClass = s[HEADER_TEXT_ALIGNMENT[headerTextAlignment]]
  const {experiments} = useExperiments()
  const eventsEditorOOIEnabled = experiments.enabled('specs.events.ui.EventsEditorOOI')
  const membersAreaInstalled = installedApps.membersArea
  const [forceShowPersonalAgenda, setForceShowPersonalAgenda] = useState(false)

  useEffect(() => {
    if (Wix && isEditor) {
      Wix.addEventListener('SETTINGS_UPDATED', event => {
        if (event?.tab === SettingsTab.ButtonDesign) {
          setForceShowPersonalAgenda(event?.type === SettingsUpdatedEvent.ForceShow)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (eventsEditorOOIEnabled && isEditor) {
      if (forceShowPersonalAgendaUI === SettingsUpdatedEvent.ForceShow) {
        setForceShowPersonalAgenda(true)
      } else {
        setForceShowPersonalAgenda(false)
      }
    }
  }, [forceShowPersonalAgendaUI])

  if (isMobile) {
    return <MobileHeader alignmentClass={alignmentClass} />
  }

  return (
    <div className={alignmentClass}>
      {personalAgendaEnabled || forceShowPersonalAgenda ? (
        <PersonalAgendaHeader membersAreaInstalled={membersAreaInstalled} />
      ) : null}
      {showDateAndLocation && (
        <div className={s.dateLocation}>
          <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
          <span>{formattedEventDateAndLocation}</span>
        </div>
      )}
      <h1 className={s.title} data-hook={DH.SCHEDULE_TITLE}>
        {settings.get(settingsParams.scheduleTitleText)}
      </h1>
      <Button eventDetailsUrl={eventDetailsUrl} navigate={navigate} isSite={isViewer} />
    </div>
  )
}
